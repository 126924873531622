// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';

import { Row, Col } from 'antd/lib/grid';
import { LinkOutlined } from '@ant-design/icons';
import Slider from 'antd/lib/slider';
import InputNumber from 'antd/lib/input-number';
import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';
import Select from 'antd/lib/select';

import CVATTooltip from 'components/common/cvat-tooltip';
import { clamp } from 'utils/math';
import { BaseOptionType } from 'rc-select/lib/Select';

interface Props {
    startFrame: number;
    stopFrame: number;
    frameNumber: number;
    frameFilename: string;
    focusFrameInputShortcut: string;
    inputFrameRef: React.RefObject<Input>;
    onSliderChange(value: number): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
    jobInstance: any;
}

function PlayerNavigation(props: Props): JSX.Element {
    const {
        startFrame,
        stopFrame,
        frameNumber,
        frameFilename,
        focusFrameInputShortcut,
        inputFrameRef,
        onSliderChange,
        onInputChange,
        onURLIconClick,
        jobInstance,
    } = props;

    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);
    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    const [frames, setFrames] = useState<{ text: string, value: string }[]>([]);
    useEffect(() => {
        const promises = [];
        for (let i = startFrame; i <= stopFrame; i++) {
            promises.push(jobInstance.frames.get(i));
        }
        Promise.all(promises).then((resArray) => {
            setFrames(resArray.map((item: any) => ({
                text: item.filename,
                value: String(item.number),
            })));
        });
    }, []);

    const handleChange = (value: string) => {
        onInputChange(Number(value));
    };

    const { Option } = Select;

    return (
        <>
            <Col className='cvat-player-controls'>
                <Row align='bottom'>
                    <Col>
                        <Slider
                            className='cvat-player-slider'
                            min={startFrame}
                            max={stopFrame}
                            value={frameNumber || 0}
                            onChange={onSliderChange}
                        />
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col className='cvat-player-filename-wrapper'>
                        <CVATTooltip title={frameFilename}>
                            <Text type='secondary'>{frameFilename}</Text>
                        </CVATTooltip>
                    </Col>
                    <Col offset={1}>
                        <CVATTooltip title='Create frame URL'>
                            <LinkOutlined className='cvat-player-frame-url-icon' onClick={onURLIconClick} />
                        </CVATTooltip>
                    </Col>
                </Row>
            </Col>
            <Col style={{ display: 'flex' }}>
                <InputNumber
                    ref={inputFrameRef}
                    className='cvat-player-frame-selector'
                    type='number'
                    value={frameInputValue}
                    onChange={(value: number | undefined | string | null) => {
                        if (typeof value !== 'undefined' && value !== null) {
                            setFrameInputValue(Math.floor(clamp(+value, startFrame, stopFrame)));
                        }
                    }}
                    onBlur={() => {
                        onInputChange(frameInputValue);
                    }}
                    onPressEnter={() => {
                        onInputChange(frameInputValue);
                    }}
                />
                <Select
                    showSearch
                    placeholder='Поиск по фото'
                    style={{ minWidth: 100, maxWidth: 175 }}
                    value={String(frameInputValue)}
                    optionFilterProp='children'
                    filterOption={(input: string, option: any) => option.children.toLowerCase().includes(input.toLowerCase())}
                    onChange={handleChange}
                    notFoundContent='Фото не найдено'
                >
                    {
                        frames.map((frame) => (
                            <Option key={frame.value} value={frame.value}>{frame.text}</Option>
                        ))
                    }
                </Select>
            </Col>
        </>
    );
}

export default React.memo(PlayerNavigation);
