// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import getCore from 'cvat-core-wrapper';
import { updateProjectAsync } from 'actions/projects-actions';
import LabelsEditor from 'components/labels-editor/labels-editor';
import BugTrackerEditor from 'components/task-page/bug-tracker-editor';
import UserSelector from 'components/task-page/user-selector';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';

const core = getCore();

interface DetailsComponentProps {
    project: any;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { project } = props;
    const cvat = getCore();
    const [tasks, setTasks] = useState<{ id: number, name: string }[]>([]);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
    useEffect(() => {
        cvat.projects.getCompactTasks(project.id).then(setTasks);
    }, []);

    const dispatch = useDispatch();
    const [projectName, setProjectName] = useState(project.name);

    const handleChangeSelect = (value: any) => {
        setSelectedTasks(value);
    };

    const joinTasks = async () => {
        if (isLoading) return;
        setisLoading(true);
        const fileUrl = await cvat.projects.joinTasks(project.id, selectedTasks);
        setisLoading(false);
        const downloadAnchor = window.document.getElementById('downloadAnchor') as HTMLAnchorElement;
        downloadAnchor.href = fileUrl;
        downloadAnchor.click();
    };

    return (
        <div cvat-project-id={project.id} className='cvat-project-details'>
            <Row>
                <Col>
                    <Title
                        level={4}
                        editable={{
                            onChange: (value: string): void => {
                                setProjectName(value);
                                project.name = value;
                                dispatch(updateProjectAsync(project));
                            },
                        }}
                        className='cvat-text-color cvat-project-name'
                    >
                        {projectName}
                    </Title>
                </Col>
            </Row>
            <Row justify='space-between' className='cvat-project-description'>
                <Col>
                    <Text type='secondary'>
                        {`Project #${project.id} created`}
                        {project.owner ? ` by ${project.owner.username}` : null}
                        {` on ${moment(project.createdDate).format('MMMM Do YYYY')}`}
                    </Text>
                    <BugTrackerEditor
                        instance={project}
                        onChange={(bugTracker): void => {
                            project.bugTracker = bugTracker;
                            dispatch(updateProjectAsync(project));
                        }}
                    />
                </Col>
                <Col>
                    <Text type='secondary'>Assigned to</Text>
                    <UserSelector
                        value={project.assignee}
                        onSelect={(user) => {
                            project.assignee = user;
                            dispatch(updateProjectAsync(project));
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{ marginBottom: 8 }}>
                    <Select
                        mode='multiple'
                        showSearch
                        placeholder='Поиск по задачам'
                        style={{ width: 400 }}
                        optionFilterProp='children'
                        filterOption={(input: string, option: any) => option.children.toLowerCase().includes(input.toLowerCase())}
                        onChange={handleChangeSelect}
                        notFoundContent='Задач не найдено'
                    >
                        {
                            tasks.map((task) => (
                                <Select.Option key={task.id} value={task.id}>{task.name}</Select.Option>
                            ))
                        }
                    </Select>
                    <Button type='primary' onClick={joinTasks} loading={isLoading} disabled={selectedTasks.length === 0}>
                        Join tasks datasets
                    </Button>
                </Col>
            </Row>
            <LabelsEditor
                labels={project.labels.map((label: any): string => label.toJSON())}
                onSubmit={(labels: any[]): void => {
                    project.labels = labels.map((labelData): any => new core.classes.Label(labelData));
                    dispatch(updateProjectAsync(project));
                }}
            />
        </div>
    );
}
